<template>
    <v-data-table
        :page="page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="messages"
        item-key="message"
        class="elevation-1 rounded-t-lg"
        id="manager-list-messages"
        :options.sync="options"
        :server-items-length="totalMessages"
        :footer-props="{ 'items-per-page-options': [10, 15, 25] }"
        :loading="loading"
        loading-text="Se incarca mail-uri... "
    >
        <template v-slot:top>
            <div class="p-5 align-items-center rounded-t-lg top py-5 gap-2 gap-md-5">
                <div class="d-flex flex-wrap gap-1">
                    <span class="h3">Mesaje catre agenti</span>
                    <!--begin::Delete-->
                    <!-- <a
                        href="#"
                        class="btn btn-sm btn-icon btn-light btn-active-light-primary"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title=""
                        data-bs-original-title="Delete"
                    > -->
                    <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg-->
                    <!-- <span class="svg-icon svg-icon-2">
                            <inline-svg
                                src="/media/svg/icons/Communication/Send.svg"
                            ></inline-svg>
                        </span> -->
                    <!--end::Svg Icon-->
                    <!-- </a> -->
                    <!--end::Delete-->
                </div>

                <div class="d-flex align-items-center flex-wrap gap-2">
                    <!--begin::Search-->

                    <!-- <div class="d-flex align-items-center position-relative mr-2"> -->
                    <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                    <!-- <span class="svg-icon svg-icon-2 position-absolute ms-4">
                            <inline-svg
                                src="/media/svg/icons/Communication/Send.svg"
                            ></inline-svg>
                        </span> -->
                    <!--end::Svg Icon-->
                    <!-- <input
                            type="text"
                            data-kt-inbox-listing-filter="search"
                            class="form-control form-control-sm form-control-solid mw-100 min-w-150px min-w-md-200px ps-12"
                            placeholder="Search Inbox"
                        /> -->
                    <!-- </div> -->
                    <!--end::Search-->
                    <b-button class="mr-2" @click="refreshNewMessages()">
                        <b-icon icon="arrow-clockwise"></b-icon>
                    </b-button>

                    <NewMessage />
                </div>
            </div>
            <b-modal
                id="notify-agent-modal"
                :title="'Mesaj #' + item.id"
                size="lg"
                hide-footer
            >
                <h4>Continut mesaj</h4>
                <p class="my-4 pl-1 grey lighten-2">{{ item.message }}</p>
                <br />
                <h4>Agenti notificati</h4>
                <div class="v-application--is-ltr theme--light">
                    <v-data-table
                        :headers="notifyAgentsHeaders"
                        :items="item.notified_users"
                        item-key="id"
                        class="elevation-1 rounded-t-lg"
                        id="notified-agents-list"
                    >
                        <template v-slot:item.number="{ item, index }">
                            {{ index + 1 }}
                        </template>
                        <template v-slot:item.picture="{ item }">
                            <span class="symbol symbol-35 mr-1 d-flex align-items-center">
                                <span
                                    v-if="
                                        item.photoUrl !=
                                        'https://sharedmailapi.pefoc.ro/img/default-avatar.png'
                                    "
                                    class="symbol-label font-size-h5 font-weight-bold symbol-photo"
                                >
                                    <img
                                        style="width: 100%"
                                        alt="Pic"
                                        :src="item.photoUrl"
                                        class="rounded"
                                    />
                                </span>
                                <span
                                    v-else
                                    class="symbol-label font-size-h5 font-weight-bold"
                                >
                                    {{ item.fullName.charAt(0) }}
                                </span>
                            </span>
                        </template>
                        <template v-slot:item.status="{ item }">
                            <span
                                v-if="!item.read_at"
                                class="d-flex align-items-center justify-center"
                                v-b-tooltip.hover
                                :title="formatDate(item.created_at)"
                            >
                                <!-- {{ item.read_at | moment("DD-MM-YY HH:mm") }} &nbsp; -->
                                <b-icon
                                    icon="check"
                                    style="width: 25px; height: 25px; color: grey"
                                ></b-icon>
                            </span>
                            <span
                                v-else-if="!item.agreed_at"
                                v-b-tooltip.hover
                                :title="formatDate(item.read_at)"
                                ><b-icon
                                    icon="check-all"
                                    style="width: 25px; height: 25px; color: grey"
                                ></b-icon
                            ></span>
                            <span
                                v-else
                                v-b-tooltip.hover
                                :title="formatDate(item.agreed_at)"
                            >
                                <b-icon
                                    icon="check-all"
                                    style="width: 25px; height: 25px; color: green"
                                ></b-icon>
                            </span>
                        </template>
                    </v-data-table>
                </div>
            </b-modal>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card v-if="canDelete">
                    <v-card-title class="text-h5"
                        >Sunteti sigur ca doriti sa stergeti acest mesaj?</v-card-title
                    >
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                            >Inchide</v-btn
                        >
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                            >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                <v-card v-else>
                    <v-card-title class="text-h5"
                        >Nu puteti sterge acest mesaj! Deja a fost confirmat de catre un
                        agent!</v-card-title
                    >
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                            >Inchide</v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <template v-slot:item.message="{ item }">
            <div class="text-dark mb-1">
                <Message :message="item" @click.native="openItem(item)" />
            </div>
        </template>
        <template v-slot:item.status="{ item }">
            <v-icon v-if="allMessagesAreConfirmed(item)" right small color="green">
                mdi-checkbox-marked-circle
            </v-icon>
            <v-icon v-else right small color="red"> mdi-close-circle </v-icon>
        </template>
        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | moment("DD-MM-YY HH:mm") }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
    </v-data-table>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import NewMessage from "@/view/pages/messages/buttons/NewMessage";
import Message from "@/view/pages/messages/modal/Message";
import moment from "moment";

export default {
    data: () => ({
        page: 1,
        numberOfPages: 0,
        options: {
            itemsPerPage: 10,
        },
        totalMessages: 0,
        loading: false,
        headers: [
            {
                text: "Subiect",
                sortable: false,
                value: "message",
            },
            {
                text: "Status",
                value: "status",
                width: "5%",
                sortable: false,
            },
            { text: "Data", value: "created_at", width: "10%" },
            {
                text: "Actions",
                value: "actions",
                fixed: true,
                width: "5%",
                sortable: false,
            },
        ],
        notifyAgentsHeaders: [
            {
                text: "Nr. crt.",
                value: "number",
                width: "5%",
            },
            {
                text: "",
                value: "picture",
                width: "5%",
            },
            {
                text: "Nume",
                value: "fullName",
            },
            {
                text: "Status",
                value: "status",
                width: "10%",
            },
        ],
        dialog: false,
        itemIndex: -1,
        item: {},
        dialogDelete: false,
        canDelete: false,
    }),

    components: {
        NewMessage,
        Message,
    },

    watch: {
        options: {
            handler() {
                this.getData();
            },
            deep: true,
        },
    },

    computed: {
        messages() {
            // console.log("front end", this.$store.getters["messages/messages"].messages);
            return this.$store.getters["messages/messages"].messages;
        },
    },
    methods: {
        getData() {
            this.loading = true;

            let payload = {
                // search: this.form.searchkey,
                per_page: this.options.itemsPerPage,
                page: this.options.page,
            };

            this.$store.dispatch("messages/getMessages", payload).then((response) => {
                if (response) {
                    this.loading = false;
                    this.totalMessages = response.page.totalElements;
                    this.numberOfPages = response.page.totalPages;
                } else {
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                }
            });
        },
        openItem(item) {
            this.itemIndex = this.messages.indexOf(item);
            this.item = Object.assign({}, item);
            this.$bvModal.show("notify-agent-modal");
        },
        deleteItem(item) {
            // getMessages
            this.canDelete = !this.isConfirmed(item.notified_users);

            this.itemIndex = this.messages.indexOf(item);
            this.item = Object.assign({}, item);
            this.dialogDelete = true;
        },

        isConfirmed(agents) {
            for (let notifiedAgent of agents) {
                if (notifiedAgent.agreed_at) {
                    return true;
                }
            }
            return false;
        },

        deleteItemConfirm() {
            this.$store.dispatch("messages/deleteMessage", this.item.id);
            // this.messages.splice(this.editedIndex, 1)
            this.closeDelete();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.canDelete = false;
            this.$nextTick(() => {
                this.itemIndex = {};
                this.item = -1;
            });
        },
        refreshNewMessages() {
            this.options.page = 1;
            this.$store.dispatch("messages/getMessages", {
                per_page: this.options.itemsPerPage,
                page: this.options.page,
            });
        },
        allMessagesAreConfirmed(item) {
            for (let notifiedAgent of item.notified_users) {
                if (notifiedAgent.agreed_at === null) return false;
            }
            return true;
        },
        formatDate(date) {
            return moment(date).format("DD-MM-YY HH:mm");
        },
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Mail Pefoc.ro - Lista mesaje" }]);
    },
    created() {
        // console.log("deprtaments", this.departments.length);
        if (!this.$store.getters["departments/allDepartments"].length) {
            // console.log("intra");
            this.$store.dispatch("departments/getPasareDepartments");
        }
        this.getData();
    },
};
</script>

<style>
#manager-list-messages td {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
}

#manager-list-messages tr {
    border-bottom-width: 1px;
    border-bottom-style: dashed;
    border-bottom-color: #eff2f5;
}

#manager-list-messages .top {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    min-height: 70px;
    padding: 0 2.25rem;
    background-color: transparent;
    border-bottom: 1px solid #eff2f5;
}
</style>
