<template>
    <!--begin::Heading-->
    <a class="text-dark">
        <span class="font-weight-bold subtitle-1">{{
            truncateString(message.message, 100)
        }}</span>
    </a>
    <!--end::Heading-->
</template>

<script>
export default {
    props: ["message"],

    methods: {
        truncateString(str, num) {
            if (str.length <= num) {
                return str;
            }
            return str.slice(0, num) + "...";
        },
    },
};
</script>
