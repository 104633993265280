<template>
    <div>
        <a
            href="#"
            class="btn btn-primary btn-active-light-primary"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title=""
            data-bs-original-title="Delete"
            v-b-modal.new-notify-message
        >
            <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg-->
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/svg/icons/Communication/Send.svg"></inline-svg>
                Mesaj nou
            </span>
            <!--end::Svg Icon-->
        </a>

        <b-modal id="new-notify-message" title="Mesaj nou" class="p-0" hide-footer>
            <div>
                <b-form @submit="onSubmit" @reset="onReset">
                    <b-form-group>
                        <b-form-textarea
                            id="textarea"
                            v-model="text"
                            placeholder="Scrie mesaj..."
                            rows="3"
                            max-rows="6"
                            required
                        ></b-form-textarea>
                    </b-form-group>
                    <div class="my-wrap">
                        <h3>Lista departamente</h3>
                        <!-- <vue-horizontal class="horizontal" ref="horizontal"> -->
                        <b-form-checkbox
                            @change="toggle"
                            v-model="allSelected"
                            class="item"
                        >
                            Toti
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-for="department in departments"
                            :key="'dep' + department.id"
                            class="item"
                            v-model="selectedDeparts"
                            :value="department"
                            @change="sel($event, department)"
                            >{{ department.name }}</b-form-checkbox
                        >

                        <!-- </vue-horizontal> -->
                    </div>

                    <br />

                    <h3>Lista agenti</h3>
                    <p v-if="agentValidate" class="text-danger">
                        Cel putin un agent trebuie selectat
                    </p>

                    <ul v-if="departments.length > 0 && agents">
                        <b-checkbox
                            v-for="user in agents"
                            :key="'checkbox' + user.id"
                            :id="user.id"
                            :value="user"
                            v-model="selectedUsers"
                            @change="selAgent"
                            >{{ user.firstName + " " + user.lastName }}</b-checkbox
                        >
                    </ul>
                    <!-- <div class="right">
                    <div>Selected Users::</div>
                    <div v-for="user in selectedUsers" :key="'agent' + user.id">
                        {{ user.firstName + " " + user.lastName }}
                    </div>
                </div>
                <br />
                <div class="right">
                    <div>Selected Departs::</div>
                    <div v-for="depart in selectedDeparts" :key="depart.id">
                        {{ depart.name }}
                    </div>
                </div> -->

                    <b-button type="reset" variant="danger" class="mr-2">Reset</b-button>
                    <b-button type="submit" variant="primary">Trimite mesaj</b-button>
                </b-form>
            </div>
        </b-modal>
    </div>
</template>

<script>
// import VueHorizontal from "vue-horizontal";

export default {
    data() {
        return {
            selected: [],
            allSelected: false,
            left: 0,
            items: [1, 2, 3],
            text: "",
            selectedUsers: [],
            selectedDeparts: [],
            agentValidate: false,
        };
    },

    // components: { VueHorizontal },

    computed: {
        departments() {
            return this.$store.getters["departments/allDepartments"];
            // return this.$store.getters["departments/departments"].filter((depart) => {
            //     return depart.isManager;
            // });
        },
        agents() {
            let agents = [];

            this.departments.map((depart) => {
                depart.users.map((user) => {
                    // user.depAc = [];
                    // daca user nu se afla in agents, adauga in agents
                    this.add(agents, user);
                });
            });
            // console.log(agents);
            return agents;
        },
    },

    methods: {
        add(arr, agent, depart_id = 0) {
            const { length } = arr;
            const id = length + 1;
            const found = arr.some((el) => el.id === agent.id);
            if (!found) {
                // if (depart_id) agent.depAc.push(depart_id);
                arr.push(agent);
                // console.log(agent);
            }
            return arr;
        },
        remove(arr, agent) {
            // console.log("array", arr);
            // console.log("agent", agent);
            const found = arr.some((el) => el.id === agent.id);
            if (found) {
                const index = arr.findIndex((el) => el.id === agent.id);
                // console.log("index", index);
                arr.splice(index, 1);
            }
            // console.log("found"?, found);
        },
        sel(checked, depart) {
            if (this.departments.length !== this.selectedDeparts.length) {
                this.allSelected = false;
                // console.log("diferit");
            } else {
                this.allSelected = true;
            }

            depart.users.map((agent, index) => {
                // console.log("checked", checked);
                // console.log("index agent", index);
                if (checked.filter((e) => e.id === depart.id).length > 0) {
                    this.add(this.selectedUsers, agent, depart.id);
                } else {
                    let isDuplicateAgent = false;

                    // console.log("selected department", this.selectedDeparts);

                    this.selectedDeparts.map((selDepart) => {
                        if (selDepart.id !== depart.id) {
                            selDepart.users.map((agentAc) => {
                                // console.log(agentAc);
                                if (agentAc.id === agent.id) {
                                    // console.log("agent duplicate", agentAc.email);
                                    isDuplicateAgent = true;
                                }
                            });
                            // console.log("selDepart", selDepart.id + selDepart.name);
                        }
                    });
                    //verifica daca nu e in alt departament activ
                    if (!isDuplicateAgent) {
                        this.remove(this.selectedUsers, agent);
                    }
                }
            });
        },
        selAgent(checked) {
            if (checked.length) {
                this.agentValidate = false;
            }
            this.allSelected =
                this.agents.length !== this.selectedUsers.length ? false : true;
        },
        toggle(checked) {
            // console.log("toti", checked);
            this.toggleAll(checked);
        },
        toggleAll(checked) {
            this.selectedDeparts = checked ? [...this.departments] : [];
            this.selectedUsers = checked ? [...this.agents] : [];
        },
        onWheel(event) {
            this.left = this.left + event.deltaY;
            this.left = this.left + event.deltaX;
            this.$refs.horizontal.scrollToLeft(this.left, "auto");
        },
        onScrollDebounce({ left }) {
            clearTimeout(this.scrollDebounceId);
            this.scrollDebounceId = setTimeout(() => {
                this.left = left;
            }, 500);
        },
        onSubmit(event) {
            event.preventDefault();
            if (this.validate()) {
                let agentIds = new Array();
                this.selectedUsers.map((agent) => agentIds.push(agent.id));
                // console.log("on submit");
                let payload = {
                    message: this.text,
                    users: agentIds,
                };

                this.$store.dispatch("messages/sendMessage", payload).then(() => {
                    this.$bvModal.hide("new-notify-message");
                    this.onReset();
                    // this.show = false;
                    // this.$nextTick(() => {
                    //   this.show = true;
                    // });
                });
            }
        },
        onReset() {
            // Reset our form values
            this.text = "";
            this.selectedUsers = [];
            this.selectedDeparts = [];
            // Trick to reset/clear native browser form validation state
            //   this.show = false;
            //   this.$nextTick(() => {
            //     this.show = true;
            //   });
        },
        validate() {
            this.agentValidate = this.selectedUsers.length ? true : false;
            return this.agentValidate;
        },
    },
    watch: {},

    mounted() {
        // console.log(this.departments);
    },
};
</script>

<style scoped>
#deplist {
    height: 50px;
}

.item {
    margin-right: 16px;
    display: inline-block;
}

/* .item >>> label {
    background-color: white !important;
    border-color: #000 !important;
} */

.my-wrap {
}
</style>
